import { groceriesApiBffURL } from "@whitelabel-webapp/shared/config";
import axios, { AxiosInstance } from "axios";

import { Coordinate, CoordinateResponse } from "./coordinate";
import { RuleResponse, Rules } from "./rules";
import { getClientIp } from "@whitelabel-webapp/shared/ip-utils";

export type AutocompleteAddressesResponse = {
  rules: RuleResponse[];
  addresses: AutocompleteAddressResponse[];
  provider: string;
};

export type AutocompleteAddressResponse = {
  country: string;
  state: string;
  city: string;
  neighborhood: string;
  streetName: string;
  coordinates: CoordinateResponse;
  postalCode: string;
  streetNumber?: string;
};

export class AutocompleteAddress {
  static fromApi(
    rawAddress: AutocompleteAddressResponse,
    rawRules?: RuleResponse[],
  ) {
    return new AutocompleteAddress(
      rawAddress.country,
      rawAddress.state,
      rawAddress.city,
      rawAddress.neighborhood,
      rawAddress.streetName,
      Coordinate.fromApi(rawAddress.coordinates),
      rawAddress.postalCode,
      rawAddress.streetNumber,
      rawRules ? Rules.fromApi(rawRules) : undefined,
    );
  }

  constructor(
    public country: string,
    public state: string,
    public city: string,
    public neighborhood: string,
    public streetName: string,
    public coordinates: Coordinate,
    public postalCode: string,
    public streetNumber?: string,
    public rules?: Rules,
  ) {}
}
export class AutocompleteAddresses {
  static client: AxiosInstance;

  static initClient(): void {
    AutocompleteAddresses.client = axios.create({
      baseURL: groceriesApiBffURL,
      headers: {
        "X-Ifood-Client-Ip": getClientIp(),
      },
    });
  }

  static async getAddressesByGeocode(
    query: string,
  ): Promise<AutocompleteAddressesResponse> {
    if (!AutocompleteAddresses.client) {
      AutocompleteAddresses.initClient();
    }

    const { data } =
      await AutocompleteAddresses.client.get<AutocompleteAddressesResponse>(
        `/logistics/v1/addresses:geocode`,
        {
          params: {
            query,
          },
        },
      );

    return data;
  }

  static async getAddressesByReverseGeocode(
    latitude: number,
    longitude: number,
  ): Promise<AutocompleteAddressesResponse> {
    if (!AutocompleteAddresses.client) {
      AutocompleteAddresses.initClient();
    }

    const { data } =
      await AutocompleteAddresses.client.get<AutocompleteAddressesResponse>(
        `/logistics/v1/addresses:reverse-geocode`,
        {
          params: {
            latitude,
            longitude,
          },
        },
      );

    return data;
  }

  static fromApi(rawResponse: AutocompleteAddressesResponse) {
    const list = rawResponse.addresses.map((address) =>
      AutocompleteAddress.fromApi(address, rawResponse.rules),
    );
    return new AutocompleteAddresses(list);
  }

  constructor(public list: AutocompleteAddress[]) {}
}
