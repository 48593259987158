import styled, { DefaultTheme } from "styled-components";
import { Divider as PomodoroDivider } from "@whitelabel-webapp/shared/design-system";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Divider = styled(PomodoroDivider)`
  margin: ${({ theme }) => theme.space.none};
  border-color: ${({ theme }) => theme.colors.grayLight};
`;
