import { PLATFORM } from "@whitelabel-webapp/authentication/shared/constants";
import { isMobileBrowser } from "@whitelabel-webapp/shared/browser-utils";
import {
  groceriesApiBffURL,
  getDeviceId,
  getSessionId,
} from "@whitelabel-webapp/shared/config";
import axios, { AxiosInstance } from "axios";
import pkg from "../../../../../package.json";

import {
  Authentication,
  AuthenticationJSON,
  AuthenticationResponse,
} from "./authentication";
import { IdentityProviderNames } from "./otp";
import { User, UserJSON, UserResponse } from "./user";
import { createAuthenticatedAxiosClient } from "../../utils/src/authentication";
import { getClientIp } from "@whitelabel-webapp/shared/ip-utils";

type RefreshTokenResponse = {
  access_token: string;
  refresh_token: string;
};

type EnrollResponse = {
  user: UserResponse;
  authentication: AuthenticationResponse;
};

type RevokeAuthenticationResponse = {
  account_id: string;
  deauthenticated: boolean;
  devices: {
    id: string;
  }[];
};

export type CustomerJSON = {
  user: UserJSON;
  authentication: AuthenticationJSON;
};

type TokenPayload = {
  token: string;
  identity_provider: string;
};

type IfoodPublicKeyPayload = {
  cloud_id: string;
  plataform: string;
  public_key: string;
  algorithm: string;
  device_id: string;
};

export type EnrollIfoodPayload = {
  name: string;
  tenant_id: string;
  device_id: string;
  language: string;
  primary_token: TokenPayload;
  secondary_token?: TokenPayload;
  public_key?: IfoodPublicKeyPayload;
};

type ProfilingPayload = {
  tenant_id: string;
  enrollment_token: {
    token: string;
    identity_provider: "OTP_EMAIL";
  };
};

type CompleteEnrollPayload = {
  emailToken: string;
  accessToken: string;
  contextId: string;
  tenantId: string;
};

type Phone = {
  id: number;
  areaCode: number;
  phone: number;
};

export type CustomerPayload = {
  id: number;
  uuid: string;
  name: string;
  email: string;
  cpf: string;
  phones: Phone[];
  tags: string[];
};

export type LocationPayload = {
  zipCode: number;
  lat: number;
  lon: number;
  address: string;
  district: string;
  country: string;
  state: string;
  city: string;
};

export type AddressPayload = {
  uuid: string;
  addressId: number;
  streetNumber: number;
  location: LocationPayload;
  complement?: string;
  reference?: string;
};

export class Customer {
  static bffClient: AxiosInstance;
  static authenticatedClient: AxiosInstance;

  static initBffClient(): void {
    Customer.bffClient = axios.create({
      baseURL: groceriesApiBffURL,
      headers: {
        app_name: pkg.name,
        app_version: pkg.version,
        "X-Ifood-Device-Id": getDeviceId(),
        "X-Ifood-Session-Id": getSessionId(),
        "X-Ifood-Client-Ip": getClientIp(),
        "accept-language": "pt-BR,pt;q=1",
        platform: isMobileBrowser.any() ? PLATFORM.MOBILE : PLATFORM.DESKTOP,
      },
    });
  }

  static initAuthenticatedClient(): void {
    Customer.authenticatedClient =
      createAuthenticatedAxiosClient(groceriesApiBffURL);
  }

  static fromApi(rawUserResponse: EnrollResponse) {
    return new Customer(
      User.fromApi(rawUserResponse.user),
      Authentication.fromApi(rawUserResponse.authentication),
    );
  }

  static fromJSON(rawJSON: CustomerJSON) {
    return new Customer(
      User.fromJSON(rawJSON.user),
      Authentication.fromJSON(rawJSON.authentication),
    );
  }

  static generateEnrollIfoodWithProviderPayload(
    name: string,
    emailToken: string,
    phoneToken: string,
    provider: IdentityProviderNames,
    tenantId: string,
  ): EnrollIfoodPayload {
    return {
      name,
      tenant_id: tenantId,
      device_id: getDeviceId(),
      language: "pt_br",
      primary_token: {
        token: phoneToken,
        identity_provider: "OTP_PHONE",
      },
      secondary_token: {
        token: emailToken,
        identity_provider: provider,
      },
    };
  }

  static generateEnrollIfoodPayload(
    name: string,
    emailToken: string,
    phoneToken: string,
    tenantId: string,
  ): EnrollIfoodPayload {
    return {
      name,
      tenant_id: tenantId,
      device_id: getDeviceId(),
      language: "pt_br",
      primary_token: {
        token: emailToken,
        identity_provider: "OTP_EMAIL",
      },
      secondary_token: {
        token: phoneToken,
        identity_provider: "OTP_PHONE",
      },
    };
  }

  static generateProfilingPayload(
    emailToken: string,
    tenantId: string,
  ): ProfilingPayload {
    return {
      tenant_id: tenantId,
      enrollment_token: {
        token: emailToken,
        identity_provider: "OTP_EMAIL",
      },
    };
  }

  static async aboutAccount(accessToken: string) {
    if (!Customer.bffClient) {
      Customer.initBffClient();
    }

    const { data } = await Customer.bffClient.get<UserResponse>(
      "/v1/customers/me/account",
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return data;
  }

  static async enrollIfoodUser(payload: EnrollIfoodPayload) {
    if (!Customer.bffClient) {
      Customer.initBffClient();
    }

    const { data } = await Customer.bffClient.post<EnrollResponse>(
      "/v3/customers",
      payload,
    );

    return data;
  }

  static async completeEnroll({
    emailToken,
    accessToken,
    contextId,
    tenantId,
  }: CompleteEnrollPayload): Promise<EnrollResponse> {
    if (!Customer.bffClient) {
      Customer.initBffClient();
    }

    const { data } = await Customer.bffClient.put<EnrollResponse>(
      "/v1/customers/profiling",
      Customer.generateProfilingPayload(emailToken, tenantId),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-Ifood-Auth-Context-Id": contextId,
        },
      },
    );

    return data;
  }

  static async refreshAccessToken(refreshToken: string) {
    if (!Customer.bffClient) {
      Customer.initBffClient();
    }

    const { data } = await Customer.bffClient.post<RefreshTokenResponse>(
      "/v2/access_tokens",
      {
        refresh_token: refreshToken,
      },
    );

    return data;
  }

  static async revokeAuthentication() {
    if (!Customer.authenticatedClient) {
      Customer.initAuthenticatedClient();
    }

    const { data } =
      await Customer.authenticatedClient.post<RevokeAuthenticationResponse>(
        `/authentications:revoke`,
        {
          devices: [{ id: getDeviceId() }],
        },
      );

    return data;
  }

  constructor(
    public user: User,
    public authentication: Authentication,
    public addressId: string = "",
    public hasOrder: boolean = false,
  ) {}

  toJSON(): CustomerJSON {
    return {
      user: this.user,
      authentication: this.authentication,
    };
  }

  toPayload(): CustomerPayload {
    return {
      id: this.user.externalId,
      uuid: this.user.id,
      name: this.user.name,
      email: this.user.email,
      cpf: this.user.cpf,
      phones: [
        {
          id: 0,
          areaCode: this.user.phone.areaCode,
          phone: parseInt(this.user.phone.number.replace(/\D+/g, "")),
        },
      ],
      tags: [],
    };
  }

  isWhitelabelUser() {
    return this.user.userType === "GROCERIES_WHITELABEL";
  }
}
