export const ERRORS = {
  UNEXPECTED: "Algo deu errado!, tente novamente em alguns segundos",
  EXPIRED_TOKEN: "Seu acesso expirou! Faça o login novamente.",
  CHECKOUT_DISABLED:
    "Pedidos indisponíveis no momento, tente novamente mais tarde",
  QUOTATION_API:
    "O método de entrega padrão não está disponível, escolha um dia e horário para receber seu pedido Seu pedido será agendado",
  REVIEW_CARD_INFO:
    "Ocorreu uma falha interna durante a requisição. Tente novamente.",
  UPDATE_PAYMENT:
    "Falha ao processar o pagamento. Tente novamente escolhendo outro método.",
};

export const ERROR_CODES = {
  CHECKOUT_DISABLED: "MKTC-2135",
};

export const CARD_TOKEN_COOKIE_KEY = "card-token";
