import * as S from "./styles";
import { List } from "../shared/List";
import { PriceSummary } from "../shared/PriceSummary";
import { MerchantInfo } from "./components/MerchantInfo";
import { useIsMobile } from "@whitelabel-webapp/shared/hooks";
import { NoItemsAdded } from "./components/NoItemsAdded";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { useCheckout } from "@app/domains/checkout";
import { ActionSheet } from "@whitelabel-webapp/shared/design-system";
import { useState } from "react";
import { Button, Text } from "@ifood/pomodoro-components";

type BagProps = {
  handleFinalizeOrderClick: () => void;
};

export const Bag = ({ handleFinalizeOrderClick }: BagProps) => {
  const isMobile = useIsMobile();
  const { merchant } = useMerchant();
  const { order, removeAllItems, confirmPriceChanges } = useCheckout();

  const [openClearBag, setOpenClearBag] = useState(false);

  const isMissingValue =
    order.totalItems.getValue() < merchant.minimumOrderValue.getValue();
  const hasUnavailableItems = order.itemsList.some(
    (item) => item.status == "UNAVAILABLE",
  );
  const hasPriceChangedItems = order.itemsList.some(
    (item) => item.status == "PRICE_CHANGED",
  );
  const isCheckoutButtonDisabled = isMissingValue || hasUnavailableItems;

  function handleClickClearBag() {
    setOpenClearBag(false);
    removeAllItems();
  }

  function handleCheckoutButtonClick() {
    if (hasPriceChangedItems) confirmPriceChanges();
    handleFinalizeOrderClick();
  }

  if (order.itemsList.length === 0) {
    return <NoItemsAdded />;
  }

  return (
    <S.Container>
      <MerchantInfo />
      <S.Divider />
      <List />

      <S.DrawerFooter>
        <PriceSummary />
        <S.CheckoutButton
          onClick={handleCheckoutButtonClick}
          disabled={isCheckoutButtonDisabled}
        >
          Finalizar pedido
        </S.CheckoutButton>
        <S.ButtonClearCheckout
          onClick={() => setOpenClearBag(true)}
          aria-label="Limpar"
        >
          {isMobile ? "Limpar" : "Esvaziar Sacola"}
        </S.ButtonClearCheckout>
      </S.DrawerFooter>

      <ActionSheet open={openClearBag} onClose={() => setOpenClearBag(false)}>
        <S.EmptyBagBody>
          <S.EmptyBagText>Deseja limpar a sacola?</S.EmptyBagText>
          <Text color="grayDarkest">
            Todos os itens serão removidos da sacola
          </Text>
          <Button width="100%" variant="primary" onClick={handleClickClearBag}>
            Sim
          </Button>
          <Button
            width="100%"
            variant="secondary"
            onClick={() => setOpenClearBag(false)}
          >
            Não
          </Button>
        </S.EmptyBagBody>
      </ActionSheet>
    </S.Container>
  );
};
