import { useRouter } from "next/router";
import * as S from "./styles";
import { Carousel } from "@whitelabel-webapp/shared/design-system";
import { MerchantBanner } from "@whitelabel-webapp/merchant/shared/models";
import { MARCHANT_BANNER_URL } from "@whitelabel-webapp/shared/constants";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { urlToHttpOptions } from "url";

type BannerListProps = {
  qtd?: number;
  size?: "SMALL" | "BIG";
  items: MerchantBanner[];
};

export const BannerList: React.VFC<BannerListProps> = ({
  qtd = 1,
  size = "BIG",
  items,
}) => {
  const router = useRouter();
  const { merchant } = useMerchant();

  const handleOnClick = (item: MerchantBanner) => {
    if (!item.promotionLink) return;

    if (item.openInNewPage) {
      return window.open(item.promotionLink, "_blank");
    }

    const uuidRegex =
      /\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/i;

    const url = new URL(item.promotionLink);
    const newPath = uuidRegex.test(item.promotionLink)
      ? url.pathname.replace(uuidRegex, merchant.id)
      : item.promotionLink;

    router.push(newPath);
  };

  return (
    <Carousel
      gap={size === "SMALL" ? "clamp(15px, 2.5dvw, 48px)" : "0px"}
      mobileWidth={size === "SMALL" ? "110px" : "128px"}
      simultaneousItems={qtd}
      autoRotate={size === "SMALL" ? false : true}
    >
      {items.map((item) => {
        return (
          <S.Banner
            key={item.id}
            size={size}
            role="option"
            src={`${MARCHANT_BANNER_URL}/${item.imageUrl}`}
            aria-label={item.title}
            onClick={() => handleOnClick(item)}
          ></S.Banner>
        );
      })}
    </Carousel>
  );
};
