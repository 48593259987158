/* eslint-disable max-lines */
import { Item } from "@whitelabel-webapp/catalog/shared/models";
import { ItemUnit } from "@whitelabel-webapp/checkout/shared/models";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import {
  Flex,
  Icon,
  Tabs,
  Text,
} from "@whitelabel-webapp/shared/design-system";
import { ecommerceEvents } from "@whitelabel-webapp/shared/ecommerce-events";

import { Sizes, withoutLoader } from "@whitelabel-webapp/shared/image-utils";
import NextImage from "next/image";

import { ChangeEventHandler, useEffect, useState } from "react";
import { useCheckout } from "@app/domains/checkout";
import * as S from "./styles";

import { ItemChoices } from "../itemChoices";
import { catalogAboyeur } from "@app/domains/catalog/aboyeur";
import { Observation } from "../Observation";
import { QuickAddItem } from "../QuickAddItem";
import { Tab } from "@ifood/pomodoro-components/dist/Tabs/types";

type ItemDetailsProps = {
  item: Item;
};

export const ItemDetails: React.VFC<ItemDetailsProps> = ({ item }) => {
  const { order, getItemInOrder } = useCheckout();
  const { merchant } = useMerchant();

  useEffect(() => {
    if (!item) return;
    catalogAboyeur.events.item.openItemPage(item.description, item.id);
    ecommerceEvents.viewDetail(item);
  }, [item]);

  const getCurrentUnit = (): ItemUnit => {
    const itemInOrder = getItemInOrder(item.id);
    if (itemInOrder) return itemInOrder.unit;
    return item.isWeigthVariable() ? "WEIGHT" : "UNIT";
  };

  const [unit, setUnit] = useState(getCurrentUnit());
  useEffect(() => setUnit(getCurrentUnit()), [item]);

  const handleTabsChange = (index: number, tabs: Tab[]) => {
    setUnit(String(tabs[index].key).includes("unit") ? "UNIT" : "WEIGHT");
  };

  const [observation, setObservation] = useState(
    () => getItemInOrder(item.id)?.observation ?? "",
  );

  useEffect(() => {
    const itemInOrder = getItemInOrder(item.id);
    if (!itemInOrder) return;
    setObservation(itemInOrder.observation ?? "");
  }, [order.itemsList, item]);

  if (!item) return null;

  const handleObservationChange: ChangeEventHandler<HTMLTextAreaElement> = (
    e,
  ) => {
    setObservation(e.target.value);
  };

  const price = item.getBasePrice().format();
  const isPromotionalItem = item.isPromotionalItem();
  const originalPrice = (item.unitOriginalPrice ?? item.unitPrice).format();
  const discountPercentage = item.getDiscountPercentage();
  const enableObservationField = Boolean(
    merchant.merchantConfigs.hasItemObservation && item.isWeigthVariable(),
  );

  return (
    <S.Container>
      <S.Image>
        <NextImage
          loader={withoutLoader}
          src={item.getLogoUrl(Sizes.HIGH)}
          alt={item.details}
          layout="fill"
          objectFit="cover"
        />
      </S.Image>

      <S.ItemData>
        <S.Description>
          <Flex flexDirection="column">
            <S.TextDescription>{item.description}</S.TextDescription>

            {item.details && (
              <Text fontWeight="400" mb="0" color="grayDarkest">
                {item.details}
              </Text>
            )}
          </Flex>

          <S.PriceContainer>
            {isPromotionalItem && (
              <Flex alignItems="center" gap="8px">
                <S.StrikethroughedText
                  variant="medium"
                  color="grayDarker"
                  fontWeight={300}
                >
                  {originalPrice}
                </S.StrikethroughedText>
                <S.Discount>-{discountPercentage}%</S.Discount>
              </Flex>
            )}

            <S.TextPrice color={isPromotionalItem ? "positive" : "black"}>
              {price}
              <b>
                {item.isWeigthVariable() &&
                  `A granel (${item.getFormattedUnit()} cada)`}
              </b>
            </S.TextPrice>
          </S.PriceContainer>

          {item.isWeigthVariable() && (
            <Flex flexDirection="column">
              <Flex justifyContent="space-between">
                <Text fontSize="14px" color="grayDarkest">
                  Preço do quilo
                </Text>
                <Text fontSize="14px" color="grayDarkest">
                  {item.getWeightPrice().format()}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontSize="14px" color="grayDarkest">
                  Peso estimado por item
                </Text>
                <Text fontSize="14px" color="grayDarkest">
                  {item.getProductWeight()}
                </Text>
              </Flex>
            </Flex>
          )}

          {item.hasDietaryRestriction() && (
            <>
              {item.getDietaryRestrictionInformation().map((tag) =>
                tag ? (
                  <S.Tags key={tag.label}>
                    <S.Circle>
                      <Icon size="xs" component={tag.icon} mr="smallest" />
                    </S.Circle>
                    {tag.label}
                  </S.Tags>
                ) : null,
              )}
            </>
          )}

          {item.isWeigthVariable() && (
            <Tabs
              marginTop="8px"
              marginBottom="-8px"
              width="fit-content"
              onChange={handleTabsChange}
              activeIndex={unit == "UNIT" ? 0 : 1}
            >
              <Tabs.Pane key="unit" label="Unidade"></Tabs.Pane>
              <Tabs.Pane key="weight" label="Peso"></Tabs.Pane>
            </Tabs>
          )}
        </S.Description>

        {item.choices.length > 0 ? (
          <S.ChoicesContainer>
            <ItemChoices item={item}></ItemChoices>
          </S.ChoicesContainer>
        ) : (
          <QuickAddItem
            unit={unit}
            catalogItem={item}
            observation={observation}
            enableExtraButtons={true}
          ></QuickAddItem>
        )}

        {enableObservationField && (
          <Observation value={observation} onChange={handleObservationChange} />
        )}
      </S.ItemData>
    </S.Container>
  );
};
