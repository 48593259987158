import { ItemResponse } from "..";
import { Catalog, CatalogResponse } from "./catalog";
import { MerchantResponse } from "@whitelabel-webapp/merchant/shared/models";
import { capitalizeFirstLetter } from "@whitelabel-webapp/shared/string-utils";
import { getCatalogLinks } from "./getCatalogLinks";
import { getEmptyCatalog } from "./getEmptyCatalog";

export async function getAisleCatalog(
  merchantResponse: MerchantResponse,
  catalogGroup: string,
  slug: string,
  accessToken?: string,
): Promise<CatalogResponse> {
  try {
    Catalog.initClient(accessToken);

    const linksPromise = getCatalogLinks(merchantResponse.uuid, catalogGroup);
    const aislePromise = Catalog.client.get<any[]>(`/aisle/catalog/${slug}`, {
      params: {
        merchant_id: merchantResponse.uuid,
        latitude: merchantResponse.address.latitude,
        longitude: merchantResponse.address.longitude,
        catalog_group: catalogGroup,
      },
    });

    console.error({
      message: "Fetching aisle catalog",
      context: {
        uuid: merchantResponse.uuid,
        catalogGroup,
        slug,
      },
    });

    const [aisleResult, links] = await Promise.all([
      aislePromise,
      linksPromise,
    ]);

    const itens: ItemResponse[] = aisleResult.data.map((aisleItem, index) => {
      return {
        order: index,
        id: aisleItem.id,
        code: aisleItem.id,
        details: aisleItem.description,
        description: aisleItem.name,
        unitPrice: aisleItem.price,
        unitMinPrice: aisleItem.price,
        unitOriginalPrice: aisleItem.originalPrice || aisleItem.price,
        promotionalPrice: aisleItem.promotionalPrice,
        minimumPromotionalPrice: aisleItem.minimumPromotionalPrice,
        itemMiscellaneous: aisleItem.itemMiscellaneous ?? null,
        sellingOption: aisleItem.sellingOption ?? null,
        productInfo: aisleItem.productInfo ?? null,
        logoUrl:
          aisleItem.resources?.length > 0
            ? aisleItem.resources.at(0).fileName
            : null,
      } as ItemResponse;
    });

    return {
      categories: [
        {
          order: 1,
          code: slug,
          itens: itens,
          name: capitalizeFirstLetter(slug.replaceAll("-", " ")),
        },
      ],
      links,
    } as CatalogResponse;
  } catch (error) {
    console.error({
      message: "Error fetching aisle catalog",
      context: error,
    });
    return getEmptyCatalog(merchantResponse.uuid, catalogGroup);
  }
}
