import React, { useState } from "react";
import { useCheckout } from "@app/domains/checkout";
import { ListItem } from "./ListItem";
import * as S from "./styles";
import { UnavailableItems } from "./UnavailableItems";
import { PriceChangedItems } from "./PriceChangedItems";

type ListProps = {
  enableExpandButton?: boolean;
};

export const List: React.VFC<ListProps> = ({ enableExpandButton }) => {
  const { order } = useCheckout();

  const availableItems = order.itemsList.filter(
    (item) => item.status == "AVAILABLE",
  );
  const unavailableItems = order.itemsList.filter(
    (item) => item.status == "UNAVAILABLE",
  );
  const priceChangedItems = order.itemsList.filter(
    (item) => item.status == "PRICE_CHANGED",
  );

  const listSize = availableItems.length;
  const [amount, setAmount] = useState(
    enableExpandButton && listSize > 2 ? 2 : listSize,
  );
  const showExpandButton = listSize > 2 && amount == 2 && enableExpandButton;

  return (
    <S.Container>
      <UnavailableItems unavailableItems={unavailableItems} />
      <PriceChangedItems priceChangedItems={priceChangedItems} />
      {availableItems.slice(0, amount).map((item, index) => (
        <>
          <ListItem item={item} key={item.instanceId} />
          {(index + 1 !== amount || enableExpandButton) && <S.Divider />}
        </>
      ))}
      {showExpandButton && (
        <>
          <S.Button
            variant="primary-inverted"
            onClick={() => setAmount(listSize)}
          >
            Ver todos os produtos
          </S.Button>
          <S.Divider />
        </>
      )}
    </S.Container>
  );
};
