import {
  groceriesApiBffURL,
  getDeviceId,
  getSessionId,
} from "@whitelabel-webapp/shared/config";
import axios, { AxiosInstance } from "axios";
import pkg from "../../../../../package.json";
import { isMobileBrowser } from "@whitelabel-webapp/shared/browser-utils";
import { PLATFORM } from "../../constants/src/headers";
import { getClientIp } from "@whitelabel-webapp/shared/ip-utils";

export type OTPType = "PHONE" | "EMAIL" | "WHATSAPP";

type AccessTokenResponse = {
  access_token: string;
};

export type AuthorizationCodeResponse = {
  key: string;
  timeout_in_seconds: number;
};

type SupportedCountry = {
  code: string;
  country: string;
};

type SupportedCountriesResponse = {
  supported_countries: SupportedCountry[];
};

type Source = "groceries-whitelabel" | "economic";

type AuthorizationCodePayload = {
  tenant_id: string;
  type: OTPType;
  source: Source;
  email?: string;
  phone?: {
    number: string;
    country_code: number;
    area_code: number;
  };
};

type AuthenticateUserPayload = {
  token: string;
  tenant_id: string;
  device_id: string;
  email?: string;
};

type AuthenticateUserResponse = {
  access_token: string;
  account_id: string;
  authenticated: boolean;
  refresh_token: string;
};

export type IdentityProviderNames =
  | "GOOGLE"
  | "FACEBOOK"
  | "APPLE"
  | "OTP_EMAIL"
  | "OTP_WHATSAPP"
  | "OTP_SMS";

type SupportedIdentityProvider = {
  name: IdentityProviderNames;
};

type SupportedIdentityProvidersResponse = SupportedIdentityProvider[];

export class OTP {
  static bffClient: AxiosInstance;

  static initBffClient(): void {
    const bffClient = axios.create({
      baseURL: groceriesApiBffURL,
      headers: {
        app_name: pkg.name,
        app_version: pkg.version,
        "X-Ifood-Device-Id": getDeviceId(),
        "X-Ifood-Session-Id": getSessionId(),
        "X-Ifood-Client-Ip": getClientIp(),
        "accept-language": "pt-BR,pt;q=1",
        platform: isMobileBrowser.any() ? PLATFORM.MOBILE : PLATFORM.DESKTOP,
      },
    });

    OTP.bffClient = bffClient;
  }

  static async getSupportedIdentityProviders(
    email: string,
    tenantId: string,
  ): Promise<SupportedIdentityProvider[]> {
    if (!OTP.bffClient) {
      OTP.initBffClient();
    }
    const { data } =
      await OTP.bffClient.post<SupportedIdentityProvidersResponse>(
        "/v4/identity-providers",
        {
          tenant_id: tenantId,
          email,
        },
      );

    return data;
  }

  static async authenticateUser(payload: AuthenticateUserPayload) {
    if (!OTP.bffClient) {
      OTP.initBffClient();
    }

    const { data } = await OTP.bffClient.post<AuthenticateUserResponse>(
      `/v3/identity-providers/OTP/authentications`,
      payload,
    );

    return data;
  }

  static async generateAccessToken(
    authorizationKey: string,
    code: string,
  ): Promise<string> {
    if (!OTP.bffClient) {
      OTP.initBffClient();
    }

    const { data } = await OTP.bffClient.post<AccessTokenResponse>(
      `/v2/identity-providers/OTP/access-tokens`,
      {
        key: authorizationKey,
        auth_code: code,
      },
    );

    return data ? data.access_token : "";
  }

  static async signInWithFacebook(
    token: string,
    deviceId: string,
    tenantId: string,
  ) {
    if (!OTP.bffClient) {
      OTP.initBffClient();
    }

    const { data } = await OTP.bffClient.post<AuthenticateUserResponse>(
      "/v3/identity-providers/FACEBOOK/authentications",
      {
        token,
        tenant_id: tenantId,
        device_id: deviceId,
      },
    );

    return data;
  }

  static async signInWithGoogle(
    token: string,
    deviceId: string,
    tenantId: string,
  ) {
    if (!OTP.bffClient) {
      OTP.initBffClient();
    }

    const { data } = await OTP.bffClient.post<AuthenticateUserResponse>(
      "/v3/identity-providers/GOOGLE/authentications",
      {
        token,
        tenant_id: tenantId,
        device_id: deviceId,
      },
    );

    return data;
  }

  static async sendAuthorizationCode(
    payload: AuthorizationCodePayload,
  ): Promise<AuthorizationCodeResponse> {
    if (!OTP.bffClient) {
      OTP.initBffClient();
    }

    const { data } = await OTP.bffClient.post<AuthorizationCodeResponse>(
      `/v2/identity-providers/OTP/authorization-codes`,
      payload,
    );

    return data;
  }

  static getPhoneAreaCode(number: string) {
    return number.substring(0, 2);
  }

  static getPhoneNumber(number: string) {
    return number.substring(2);
  }
}
