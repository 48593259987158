import { useCheckout } from "@app/domains/checkout";
import { ItemUnit } from "@whitelabel-webapp/checkout/shared/models";
import { Item as CatalogItem } from "@whitelabel-webapp/catalog/shared/models";
import { useEffect, useState } from "react";
import { QuickAdd } from "@whitelabel-webapp/shared/design-system";
import { useRouter } from "next/router";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";

type QuickAddItemProps = {
  catalogItem: CatalogItem;
  unit: ItemUnit;
  variant?: "large" | "small";
  observation?: string;
  enableExtraButtons?: boolean;
};

export const QuickAddItem: React.VFC<QuickAddItemProps> = ({
  catalogItem,
  unit,
  variant,
  observation,
  enableExtraButtons,
}) => {
  const { order, addItem, removeItem, getItemInOrder } = useCheckout();
  const { merchant } = useMerchant();
  const router = useRouter();

  const [item, setItem] = useState(catalogItem);
  const [quantity, setQuantity] = useState<number>();

  useEffect(() => {
    const itemInOrder = getItemInOrder(catalogItem.id);
    if (itemInOrder) {
      setItem(itemInOrder.catalogItem);
      setQuantity(itemInOrder.quantity);
    } else {
      setItem(catalogItem);
      setQuantity(0);
    }
  }, [order.itemsList, catalogItem]);

  useEffect(() => {
    const itemInOrder = getItemInOrder(item.id);
    if (itemInOrder && unit) updateItemInOrder();
  }, [unit]);

  useEffect(() => {
    const itemInOrder = getItemInOrder(item.id);
    if (!itemInOrder || !observation) return;
    const timeout = setTimeout(updateItemInOrder, 800);
    return () => clearTimeout(timeout);
  }, [observation]);

  const updateItemInOrder = async (newQuantity: number = quantity) => {
    const itemInOrder = getItemInOrder(item.id);
    const newObservation = observation ?? itemInOrder?.observation;
    const choices = itemInOrder?.choices || [];
    if (newQuantity > 0) {
      return await addItem(item, newQuantity, choices, unit, newObservation);
    } else if (quantity > 0) {
      return removeItem(item);
    }
  };

  const openItemPage = async () => {
    return router.push(`/${merchant.query}/item/${item.code}`);
  };

  const sellingOption = unit == "WEIGHT" ? item.sellingOption : undefined;
  const canQuickAdd = item.choices.length === 0 || quantity > 0;
  if (quantity === undefined) return null;

  return (
    <QuickAdd
      enableExtraButtons={enableExtraButtons}
      description={item.description}
      sellingOption={sellingOption}
      onChange={canQuickAdd ? updateItemInOrder : openItemPage}
      blocked={!canQuickAdd}
      startValue={quantity}
      variant={variant}
    ></QuickAdd>
  );
};
