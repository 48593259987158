import { useAddress } from "@whitelabel-webapp/address/shared/address-store";
import {
  AutocompleteAddress,
  CustomerAddress,
} from "@whitelabel-webapp/address/shared/models";
import { Z_INDEXES } from "@whitelabel-webapp/shared/constants";
import React, { useState } from "react";

import { Add } from "../Add";
import { Choose } from "../Choose";
import { ClosestMerchants } from "../ClosestMerchants";
import { MerchantNotFound } from "../MerchantNotFound";
import { Pin } from "../Pin";
import * as S from "./styles";

type Step =
  | "CHOOSE"
  | "PIN"
  | "ADD"
  | "CLOSEST_MERCHANTS"
  | "MERCHANT_NOT_FOUND";

type DetailsWithChainProps = {
  hidePinStep?: boolean;
};

export const DetailsWithChain: React.VFC<DetailsWithChainProps> = ({
  hidePinStep,
}) => {
  const { setAddress, closeAddress, addressConfig } = useAddress();

  const [autocompleteAddress, setAutocompleteAddress] = useState<
    AutocompleteAddress | undefined
  >(addressConfig.settings?.defaultAutocompleteAddress);
  const [step, setStep] = useState<Step>(
    addressConfig.settings?.defaultAutocompleteAddress ? "PIN" : "CHOOSE",
  );

  const showPinStep = !hidePinStep;

  async function handleAddAddress(customerAddress: CustomerAddress) {
    setAddress("CHAIN", customerAddress);
    setStep("CLOSEST_MERCHANTS");
  }

  function handleBack(step: Step) {
    if (step === "ADD" || step === "MERCHANT_NOT_FOUND") {
      if (!showPinStep) {
        setAutocompleteAddress(undefined);
        setStep("CHOOSE");
        return;
      }

      setStep("PIN");
      return;
    }

    if (step === "CLOSEST_MERCHANTS") {
      setStep("ADD");
      return;
    }

    closeAddress();
  }

  function handleMerchantNotFound() {
    setStep("MERCHANT_NOT_FOUND");
  }

  const title = {
    CHOOSE: "Buscar endereço",
    PIN: "Confirme a localização",
    ADD: "Complete seu endereço",
    CLOSEST_MERCHANTS: "Buscando a melhor loja",
    MERCHANT_NOT_FOUND: "Endereço não atendido",
  };

  return (
    <S.Drawer
      open={true}
      title={title[step]}
      zIndex={Z_INDEXES.ADDRESS_DRAWER}
      onClose={() => handleBack(step)}
    >
      <S.Wrapper>
        {step === "CHOOSE" && (
          <Choose
            onAutocompleteAddress={(address: AutocompleteAddress) => {
              setAutocompleteAddress(address);

              if (!showPinStep) {
                setStep("ADD");
                return;
              }

              setStep("PIN");
            }}
          />
        )}
        {step === "PIN" && (
          <Pin
            onChangePinLocation={(address: AutocompleteAddress) => {
              setAutocompleteAddress(address);
              setStep("ADD");
            }}
            autocompleteAddress={autocompleteAddress as AutocompleteAddress}
          />
        )}
        {step === "ADD" && (
          <Add
            onAddAddress={handleAddAddress}
            autocompleteAddress={autocompleteAddress as AutocompleteAddress}
            isEditable={!showPinStep}
          />
        )}
        {step === "CLOSEST_MERCHANTS" && (
          <ClosestMerchants onMerchantNotFound={handleMerchantNotFound} />
        )}
        {step === "MERCHANT_NOT_FOUND" && (
          <MerchantNotFound onChangeAddress={() => handleBack(step)} />
        )}
      </S.Wrapper>
    </S.Drawer>
  );
};
