import { useEffect, useState } from "react";
import * as S from "./styles";
import { SellingOption } from "@whitelabel-webapp/catalog/shared/models";
import { formatQuantity } from "./utils/formatQuantity";
import { LargeQuickAdd, SmallQuickAdd } from "./variants";

type QuickAddProps = {
  startValue: number;
  min?: number;
  max?: number;
  blocked?: boolean;
  disabled?: boolean;
  variant?: "small" | "large";
  description?: string;
  sellingOption?: SellingOption;
  enableExtraButtons?: boolean;
  onChange: (quantity: number) => Promise<boolean>;
};

export const QuickAdd: React.FC<QuickAddProps> = ({
  startValue,
  min = 0,
  max,
  onChange,
  description,
  blocked,
  disabled,
  sellingOption,
  enableExtraButtons = false,
  variant = "large",
}) => {
  const [value, setValue] = useState(1);
  const [changes, setChanges] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(startValue);
  }, [startValue]);

  const handleDecrementClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (loading || blocked || disabled) return;
    if (value > min) {
      const newValue = value - 1;
      if (newValue == 0) {
        onChange(0);
        setChanges(0);
      } else {
        setChanges((prev) => prev + 1);
      }
      setValue(newValue);
    }
  };

  const handleIncrementClick = (e: React.MouseEvent, amount = 1) => {
    e.stopPropagation();
    if (loading || blocked || disabled) return;
    if (max == undefined || value < max) {
      const newValue = value + amount;
      setChanges((prev) => prev + 1);
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (changes === 0) return;
    const timeout = setTimeout(async () => {
      setLoading(true);
      const success = await onChange(value);
      if (!success) setValue(0);
      setLoading(false);
    }, 800);
    return () => clearTimeout(timeout);
  }, [changes]);

  const isEmpty = value <= min;

  return (
    <S.QuickAddWrapper disabled={disabled} onClick={(e) => e.stopPropagation()}>
      {variant === "small" ? (
        <SmallQuickAdd
          value={value}
          description={description}
          sellingOption={sellingOption}
          handleIncrementClick={handleIncrementClick}
          handleDecrementClick={handleDecrementClick}
          isEmpty={isEmpty}
        />
      ) : (
        <LargeQuickAdd
          value={value}
          label={enableExtraButtons ? "Adicionar à sacola" : "Adicionar"}
          description={description}
          sellingOption={sellingOption}
          handleIncrementClick={handleIncrementClick}
          handleDecrementClick={handleDecrementClick}
          isEmpty={isEmpty}
        />
      )}
      {enableExtraButtons &&
        [2, 6, 12].map((amount) => (
          <S.ExtraButton
            key={amount}
            onClick={(e) => handleIncrementClick(e, amount)}
          >
            +{formatQuantity(amount, sellingOption)}
          </S.ExtraButton>
        ))}
    </S.QuickAddWrapper>
  );
};
