import React from "react";
import * as S from "./styles";
import { Item } from "@whitelabel-webapp/checkout/shared/models";
import { ListItem } from "../ListItem";

type PriceChangedItemsProps = {
  priceChangedItems?: Item[];
};

export const PriceChangedItems: React.VFC<PriceChangedItemsProps> = ({
  priceChangedItems,
}) => {
  if (!priceChangedItems) return null;

  return (
    <>
      {priceChangedItems.map((item) => (
        <>
          <ListItem item={item} key={item.instanceId} />
          <S.Divider />
        </>
      ))}
    </>
  );
};
