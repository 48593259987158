import { PaymentMethod } from "@whitelabel-webapp/checkout/shared/models";
import { imageServiceURL } from "@whitelabel-webapp/shared/config";
import { ImageLoaderProps } from "next/dist/client/image";

export enum Folders {
  MERCHANT_COVER = "capa",
  MERCHANT_LOGO = "logosgde",
  DISH = "pratos",
  RESTAURANT_LOGO = "logosgde",
  MARKET_AISLE_LOGO = "mercados/categorias",
  PAYMENT_ICON = "icones/payments/brands",
  DISCOVERIES = "discoveries",
  WEBAPP = "webapp",
  WEBAPP_CATEGORIES = "webapp/categories",
  WEBAPP_LOOP = "webapp/loop",
  WEBAPP_LANDING = "webapp/landing",
  WEBAPP_GROUP = "webapp/group-pages",
  WEBAPP_ASSETS = "webapp/assets",
  WEBAPP_JOBS = "webapp/jobs",
  WEBAPP_DISHES = "webapp/dishes",
  WEBAPP_CAREERS = "webapp/carrers",
  WEBAPP_TESTIMONIALS = "webapp/carrers/testimonials",
  WEBAPP_CHALLENGES = "webapp/carrers/challenges",
  WEBAPP_HOME = "webapp/home",
}

export enum Sizes {
  THUMBNAIL = "t_thumbnail",
  LOW = "t_low",
  MEDIUM = "t_medium",
  HIGH = "t_high",
  COVER = "",
  BANNER = "t_banner",
  AUTO = "f_auto",
}

export enum Quality {
  HIGH = "q_100",
}

export const IMAGE_COVER_THUMB_REGEX = /\.(jpe?g|png)/gi;

export const DEFAULT_MERCHANT_COVER = `/images/merchant/cover.png`;

export const getImageURL = (
  folder: Folders,
  fileName: string,
  size: Sizes,
  quality?: Quality,
) => {
  const formatedQuality = quality ? `,${quality}` : "";
  const formatedSize = size == null ? "" : size;

  const optimizations = `${formatedSize}${formatedQuality}`;

  if (size === Sizes.COVER) {
    const bannerFileName = fileName.replace(IMAGE_COVER_THUMB_REGEX, "@2x.$1");
    return `${imageServiceURL}/${optimizations}/${folder}/${bannerFileName}`;
  }

  return `${imageServiceURL}/${optimizations}/${folder}/${fileName}`;
};

export const withoutLoader = ({ src }: ImageLoaderProps) => {
  return src;
};

export const getSocialMediaIcons = (title: string) => {
  const icons: { [key: string]: string } = {
    Whatsapp: "/icons/social-media/icon-whatsapp.svg",
    Facebook: "/icons/social-media/icon-facebook.svg",
    Telegram: "/icons/social-media/icon-telegram.svg",
    Instagram: "/icons/social-media/icon-instagram.svg",
    Youtube: "/icons/social-media/icon-youtube.svg",
    X: "/icons/social-media/icon-x.svg",
  };

  return icons[title] || "/icons/ifood-smiley.svg";
};

export function getBrandImageURL(paymentMethod: PaymentMethod) {
  if (!paymentMethod) return "";

  return getBrandIconPath(
    paymentMethod.brand.name,
    paymentMethod.method.description,
  );
}

export const getBrandIconPath = (brandName: string, paymentMethodName = "") => {
  const brands = {
    ALELO: "/icons/payment/alelo.svg",
    AMEX: "/icons/payment/amex.svg",
    BANK_DRAFT: "/icons/payment/bank_draft.svg",
    BANRICOMPRAS: "/icons/payment/banricompras.svg",
    BEN_MEAL_VOUCHER: "/icons/payment/ben.svg",
    DINHEIRO: "/icons/payment/cash.svg",
    DINERS: "/icons/payment/diners.svg",
    ELO: "/icons/payment/elo.svg",
    GOODCARD: "/icons/payment/goodcard.svg",
    GREENCARD: getGreenCard(paymentMethodName),
    HIPERCARD: "/icons/payment/hipercard.svg",
    MASTERCARD: "/icons/payment/mastercard.svg",
    NUGO: "/icons/payment/nugo.svg",
    NUTRICARD: "/icons/payment/nutricard.svg",
    REFEISUL: "/icons/payment/refeisul.svg",
    SODEXO: "/icons/payment/sodexo.svg",
    TICKET: "/icons/payment/ticket.svg",
    VALE_CARD: "/icons/payment/valecard.svg",
    VEROCARD: "/icons/payment/verocard.svg",
    VISA: "/icons/payment/visa.svg",
    VISA_ELECTRON: "/icons/payment/visa.svg",
    "VISA ELECTRON": "/icons/payment/visa.svg",
    VR: "/icons/payment/vr.svg",
    PIX: "/icons/payment/pix.svg",
  };

  return (
    brands[brandName as keyof typeof brands] || "/icons/payment/default.svg"
  );
};

const getGreenCard = (paymentMethodName: string) => {
  if (paymentMethodName.includes("COOPER CARD")) {
    return "/icons/payment/greencard.svg";
  }
  if (paymentMethodName.includes("VERDECARD")) {
    return "/icons/payment/verdecard.svg";
  }

  return "/icons/payment/greencard.svg";
};
