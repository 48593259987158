import { CategoryResponse } from "..";
import { Catalog, CatalogResponse, Metadata } from "./catalog";
import { getCatalogLinks } from "./getCatalogLinks";
import { getEmptyCatalog } from "./getEmptyCatalog";

export async function getCategoryCatalog(
  uuid: string,
  catalogGroup: string,
  categoryCode: string,
  page?: number,
  accessToken?: string,
  itemsSize?: number,
): Promise<CatalogResponse> {
  try {
    Catalog.initClient(accessToken);

    const linksPromise = getCatalogLinks(uuid, catalogGroup);
    const categoryPromise = Catalog.client.get<{
      data: { categoryMenu: CategoryResponse; metadata: Metadata };
    }>(`/v1/merchants/${uuid}/catalog-category/${categoryCode}`, {
      params: {
        catalog_group: catalogGroup,
        items_page: page ?? 1,
        items_size: itemsSize ?? 48,
      },
    });

    console.error({
      message: "Fetching category catalog",
      context: {
        uuid,
        catalogGroup,
        categoryCode,
      },
    });

    const [categoryData, links] = await Promise.all([
      categoryPromise,
      linksPromise,
    ]);

    return {
      categories: [categoryData.data.data.categoryMenu],
      metadata: categoryData.data.data.metadata,
      links,
    } as CatalogResponse;
  } catch (error) {
    console.error({
      message: "Error fetching category catalog",
      context: error,
    });
    return getEmptyCatalog(uuid, catalogGroup);
  }
}
