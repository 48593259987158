import React from "react";
import { useCheckout } from "@app/domains/checkout";
import * as S from "./styles";
import { Item } from "@whitelabel-webapp/checkout/shared/models";
import { ListItem } from "../ListItem";
import { Icon } from "@ifood/pomodoro-components";
import { Close } from "@ifood/pomodoro-icons";

type UnavailableItemsProps = {
  unavailableItems?: Item[];
};

export const UnavailableItems: React.VFC<UnavailableItemsProps> = ({
  unavailableItems,
}) => {
  const { removeItem } = useCheckout();

  const removeAll = () => {
    unavailableItems.forEach((item) => {
      removeItem(item.catalogItem);
    });
  };

  if (unavailableItems.length == 0) return null;

  return (
    <S.UnavailableBox>
      <S.WarnBox>
        <S.WarnIcon />
        <S.WarnMessageArea>
          <S.WarnTitle>Alguns itens ficaram indisponíveis</S.WarnTitle>
          <S.WarnDescription>
            Substitua ou remova da sacola para prosseguir
          </S.WarnDescription>
        </S.WarnMessageArea>
      </S.WarnBox>

      <S.RemoveAllButton onClick={removeAll} variant="tertiary">
        <Icon component={Close} size="s"></Icon>
        Remover todos indisponíveis
      </S.RemoveAllButton>

      <S.ItemsWrapper>
        {unavailableItems.map((item, index) => (
          <>
            <ListItem item={item} key={item.instanceId} />
            {index + 1 !== unavailableItems.length && <S.Divider />}
          </>
        ))}
      </S.ItemsWrapper>
    </S.UnavailableBox>
  );
};
