import styled from "styled-components";

export const Main = styled.main<{ backgroundColor: string }>`
  min-height: 100dvh;
  height: auto;
  display: flex;
  flex-direction: column;
  background: ${({ backgroundColor }) => backgroundColor};

  footer {
    background-color: ${({ backgroundColor }) =>
      backgroundColor != "white" ? "white" : "#f7f7f7"};
  }
`;
