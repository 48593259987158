import { useAddress } from "@whitelabel-webapp/address/shared/address-store";
import { chainAboyeur } from "@whitelabel-webapp/chain/config";
import { useChain } from "@whitelabel-webapp/chain/shared/chain-store";
import { Loading } from "@whitelabel-webapp/shared/design-system";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";

import * as S from "./styles";

const CURRENT_ADDRESS_KEY = "current_address";

type ClosestMerchantsProps = {
  onMerchantNotFound: () => void;
};

export const ClosestMerchants: React.VFC<ClosestMerchantsProps> = ({
  onMerchantNotFound,
}) => {
  const { chain } = useChain();
  const { getAddress, closeAddress } = useAddress();
  const router = useRouter();

  const persistedAddress = useMemo(() => getAddress("CHAIN"), [getAddress]);

  useEffect(() => {
    const getClosestMerchants = async () => {
      if (!persistedAddress) return;

      try {
        chainAboyeur.events.search.closestMerchant();
        const closestMerchant = await chain.getClosestMerchant(
          persistedAddress.coordinates.latitude,
          persistedAddress.coordinates.longitude,
        );
        const persistedAddressJSON = persistedAddress.toJSON();

        if (!closestMerchant) {
          chainAboyeur.events.search.merchantNotFounded();
          onMerchantNotFound();
          return;
        }

        chainAboyeur.events.search.merchantFounded(closestMerchant.id);

        localStorage.setItem(
          `${closestMerchant.id}.${CURRENT_ADDRESS_KEY}`,
          JSON.stringify(persistedAddressJSON),
        );

        router
          .push({
            pathname: "/[chain]",
            query: { chain: chain.slug, merchant: closestMerchant.id },
          })
          .then(() => {
            closeAddress();
          });
      } catch (e: any) {
        chainAboyeur.events.catch.onError(e);
        onMerchantNotFound();
        return;
      }
    };

    getClosestMerchants();
  }, [onMerchantNotFound, chain, persistedAddress]);
  return (
    <S.Wrapper>
      <Loading variant="large" color="primary">
        Carregando...
      </Loading>
    </S.Wrapper>
  );
};
