import { useCheckout } from "@app/domains/checkout";
import { getBrandIconPath } from "@whitelabel-webapp/shared/image-utils";
import { Flex, Icon } from "@ifood/pomodoro-components";
import NextImage from "next/image";
import * as S from "./styles";
import Pix from "../PaymentSelector/Status/SelectPaymentType/Pix";
import { useMemo } from "react";
import { CardFilled } from "@ifood/pomodoro-icons";

export const SelectedPayment: React.FC<{ onSwitch: () => void }> = ({
  onSwitch,
}) => {
  const {
    order: { paymentMethod, cardToken },
  } = useCheckout();

  const isOfflineSelected = paymentMethod?.isOffline();
  const isPixSelected = paymentMethod?.isPix();
  const isOnlineSelected = paymentMethod?.isOnline() && !isPixSelected;

  const brandImageURL = useMemo(() => {
    if (!paymentMethod) return "";
    return getBrandIconPath(
      paymentMethod.brand.name,
      paymentMethod.method.description,
    );
  }, [paymentMethod]);

  const paymentName = useMemo(() => {
    if (!paymentMethod) return "Pagamento não selecionado";
    if (paymentMethod.isBankDraft()) return "Cheque";
    return paymentMethod.method.description;
  }, [paymentMethod]);

  const paymentDescription = useMemo(() => {
    if (cardToken && paymentMethod?.brand) {
      return `${paymentMethod.brand.description} •••• ${cardToken.last_four_digits}`;
    }
    return isOnlineSelected
      ? "Pague pelo site"
      : paymentMethod?.brand.description || "";
  }, [cardToken, paymentMethod, isOnlineSelected]);

  return (
    <Flex>
      {!paymentMethod && (
        <PaymentButton
          ariaLabel="Pix"
          IconComponent={<Icon component={CardFilled} height="18" width="22" />}
          paymentName="Formas de pagamento"
          paymentDescription="Escolha uma opção"
        />
      )}
      {isPixSelected && (
        <PaymentButton
          ariaLabel="Pix"
          IconComponent={<Icon component={Pix} height="18" width="22" />}
          paymentName="Pix"
          paymentDescription="Pagamento online com pix"
        />
      )}
      {isOnlineSelected && (
        <PaymentButton
          ariaLabel="Pagar pelo site"
          IconComponent={
            <PaymentImage
              src={brandImageURL}
              alt={paymentMethod?.method.description}
            />
          }
          paymentName={paymentName}
          paymentDescription={paymentDescription}
        />
      )}
      {isOfflineSelected && (
        <PaymentButton
          ariaLabel="Pagar na entrega"
          IconComponent={
            <PaymentImage
              src={brandImageURL}
              alt={paymentMethod?.method.description}
            />
          }
          paymentName={paymentName}
          paymentDescription={paymentMethod?.brand?.description.replace(
            "EXTERNAL",
            "Cartão da loja",
          )}
        />
      )}
      <S.SwitchButton onClick={onSwitch} variant="primary-inverted">
        {paymentMethod ? "Trocar" : "Escolher"}
      </S.SwitchButton>
    </Flex>
  );
};

const PaymentButton: React.FC<{
  ariaLabel: string;
  IconComponent: React.ReactNode;
  paymentName: string;
  paymentDescription: string;
}> = ({ ariaLabel, IconComponent, paymentName, paymentDescription }) => (
  <S.PaymentTypeButton aria-label={ariaLabel}>
    {IconComponent}
    <S.Container>
      {paymentName}
      <S.PaymentTypeDescripton>{paymentDescription}</S.PaymentTypeDescripton>
    </S.Container>
  </S.PaymentTypeButton>
);

const PaymentImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
  <NextImage height={18} width={22} src={src} alt={alt} />
);
