import { groceriesApiBffURL } from "@whitelabel-webapp/shared/config";
import { Category, CategoryResponse } from "../category";
import axios, { AxiosInstance } from "axios";
import axiosRetry from "axios-retry";
import { Item } from "../item";

import { getHomeCatalog } from "./getHomeCatalog";
import { getCategoryCatalog } from "./getCategoryCatalog";
import { getItemCatalog } from "./getItemCatalog";
import { getSearchCatalog } from "./getSearchCatalog";
import { getAisleCatalog } from "./getAisleCatalog";
import { getCatalogLinks } from "./getCatalogLinks";
import { getEmptyCatalog } from "./getEmptyCatalog";

export type Metadata = {
  filters: any;
  pagination: {
    items: number;
    pages: number;
  };
};

export type CatalogLink = {
  uuid: string;
  name: string;
  type: string;
};

export type CatalogResponse = {
  categories: CategoryResponse[];
  metadata?: Metadata;
  links?: CatalogLink[];
};

export class Catalog {
  static client: AxiosInstance;

  static initClient(accessToken?: string): void {
    this.client = axios.create({
      baseURL: groceriesApiBffURL,
      headers: {
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      },
    });
    axiosRetry(this.client, {
      retries: 3,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: axiosRetry.isRetryableError,
    });
  }

  static readonly getCatalog = getHomeCatalog;
  static readonly getItemCatalog = getItemCatalog;
  static readonly getAisleCatalog = getAisleCatalog;
  static readonly getSearchCatalog = getSearchCatalog;
  static readonly getCategoryCatalog = getCategoryCatalog;
  static readonly getEmptyCatalog = getEmptyCatalog;
  static readonly getCatalogLinks = getCatalogLinks;

  static fromApi(rawCatalog: CatalogResponse) {
    const links = rawCatalog.links;
    const metadata = rawCatalog.metadata;
    const categories = rawCatalog?.categories.map((category) =>
      Category.fromApi(category),
    );
    return new Catalog(categories, links, metadata);
  }

  static getAllItems(catalogResponse: CatalogResponse): Item[] {
    return catalogResponse.categories.reduce<Item[]>(
      (acc, category) =>
        acc.concat(
          category.itens.map((item) =>
            Item.fromApi(item, category.name, category.code),
          ),
        ),
      [],
    );
  }

  constructor(
    public categories: Category[],
    public links?: CatalogLink[],
    public metadata?: Metadata,
  ) {}

  isEmpty() {
    return !this.categories.some((category) => category.isVisible());
  }

  getVisibleCategories(): Category[] {
    return this.categories.filter((category) => category.isVisible());
  }
}
