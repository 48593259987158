import {
  Drawer,
  Flex,
  Input,
  Button as PomodoroButton,
  Icon as PomodoroIcon,
  Text,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

const belowDesktop = ({ theme }: { theme: any }) =>
  `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled(Drawer.Body)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

export const TitleContainer = styled.div`
  padding-right: ${({ theme }) => theme.space.regular};
  padding-left: ${({ theme }) => theme.space.regular};

  text-align: center;
  margin-bottom: ${({ theme }) => theme.space.larger};
`;

export const InfoContainer = styled(Flex)``;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Label = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.s};
`;

export const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.lineHeights.s};
`;

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.space.large};

  @media (${belowDesktop}) {
    margin-top: ${({ theme }) => theme.space.regular};
  }
`;

export const HalfInput = styled(Input)`
  width: 50%;
`;

export const InputDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.m};
`;

export const Subtitle = styled(Text)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.m};
`;

export const Button = styled(PomodoroButton)`
  width: 100%;
  margin-top: ${({ theme }) => theme.space.larger};
  border-radius: ${({ theme }) => theme.radii.s};
`;
