import {
  DrinksFilled,
  GlutenFreeFilled,
  LactoseFreeFilled,
  OrganicFilled,
  VeganFilled,
  VegetarianFilled,
  ZeroSugarFilled,
} from "@ifood/pomodoro-icons";
import { GarnishItem as CheckoutGarnishItem } from "@whitelabel-webapp/checkout/shared/garnish-item";
import { Merchant } from "@whitelabel-webapp/merchant/shared/models";
import {
  Folders,
  Quality,
  Sizes,
  getImageURL,
} from "@whitelabel-webapp/shared/image-utils";
import { Money } from "@whitelabel-webapp/shared/models";

import { Choice } from "../choice";
import { ItemWithPizzaPriceCalculator } from "./itemWithPizzaPriceCalculator";
import {
  DietaryRestrictionsTags,
  ItemJSON,
  ItemResponse,
  PortionSizesTags,
  ProductInfo,
  ProductTag,
  ProductTagGroup,
} from "./types";

import axios, { AxiosInstance } from "axios";
import { groceriesApiBffURL } from "@whitelabel-webapp/shared/config";
import {
  CategoryResponse,
  DietaryRestrictionTag,
  ItemMiscellaneous,
  SellingOption,
} from "..";

export class Item {
  static client: AxiosInstance;

  static initClient(accessToken?: string): void {
    Item.client = axios.create({
      baseURL: groceriesApiBffURL,
      headers: {
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      },
    });
  }

  static async getItem(
    itemUuid: string,
    merchantUuid: string,
    catalogGroup: string,
    accessToken?: string,
  ): Promise<{
    itemResponse: ItemResponse;
    category: { code: string; name: string };
  }> {
    Item.initClient(accessToken);

    const itemData = await Item.client.get<{
      data: { menu: CategoryResponse[] };
    }>(`/restaurant/${merchantUuid}/menuitem/${itemUuid}`, {
      params: { catalog_group: catalogGroup },
    });

    const categoryResponse = itemData.data.data.menu.at(0);
    if (!categoryResponse) throw new Error();
    const itemResponse = categoryResponse?.itens.at(0);
    if (!itemResponse) throw new Error();

    return {
      itemResponse: itemResponse,
      category: {
        code: categoryResponse?.code,
        name: categoryResponse?.name,
      },
    };
  }

  static fromApi(rawItem: ItemResponse, categoryName = "", categoryCode = "") {
    return new Item(
      rawItem.order,
      rawItem.id,
      rawItem.code,
      categoryName,
      categoryCode,
      rawItem.details,
      rawItem.description,
      new Money(rawItem.unitPrice),
      new Money(rawItem.unitMinPrice),
      rawItem.choices
        ? rawItem.choices.map((choice) => Choice.fromApi(choice))
        : [],
      undefined,
      rawItem.promotionalPrice
        ? new Money(rawItem.promotionalPrice)
        : undefined,
      rawItem.minimumPromotionalPrice
        ? new Money(rawItem.minimumPromotionalPrice)
        : undefined,
      rawItem.unitOriginalPrice
        ? new Money(rawItem.unitOriginalPrice)
        : undefined,
      rawItem.logoUrl,
      rawItem.itemMiscellaneous
        ? JSON.parse(rawItem.itemMiscellaneous)
        : undefined,
      rawItem.sellingOption,
      rawItem.productInfo,
      rawItem.productTags,
      rawItem.tags,
    );
  }

  static fromJSON(rawItem: ItemJSON) {
    return new Item(
      rawItem.order,
      rawItem.id,
      rawItem.code,
      rawItem.category,
      rawItem.categoryCode,
      rawItem.details,
      rawItem.description,
      Money.fromJSON(rawItem.unitPrice),
      Money.fromJSON(rawItem.unitMinPrice),
      rawItem.choices?.map((choice) => Choice.fromJSON(choice)) || [],
      rawItem.instanceId,
      rawItem.promotionalPrice
        ? Money.fromJSON(rawItem.promotionalPrice)
        : undefined,
      rawItem.minimumPromotionalPrice
        ? Money.fromJSON(rawItem.minimumPromotionalPrice)
        : undefined,
      rawItem.unitOriginalPrice
        ? Money.fromJSON(rawItem.unitOriginalPrice)
        : undefined,
      rawItem.logoUrl,
      rawItem.itemMiscellaneous,
      rawItem.sellingOption,
      rawItem.productInfo,
      rawItem.productTags,
      rawItem.tags,
    );
  }

  static fromPartial(partial: Partial<Item>): Item {
    return new Item(
      partial.order!,
      partial.id!,
      partial.code!,
      partial.category!,
      partial.categoryCode!,
      partial.details!,
      partial.description!,
      partial.unitPrice!,
      partial.unitMinPrice!,
      partial.choices!,
      partial.instanceId,
      partial.promotionalPrice,
      partial.minimumPromotionalPrice,
      partial.unitOriginalPrice,
      partial.logoUrl,
      partial.itemMiscellaneous,
      partial.sellingOption,
      partial.productInfo,
      partial.productTags,
      partial.tags,
    );
  }

  constructor(
    public order: number,
    public id: string,
    public code: string,
    public category: string,
    public categoryCode: string,
    public details: string,
    public description: string,
    public unitPrice: Money,
    public unitMinPrice: Money,
    public choices: Choice[],
    public instanceId?: string,
    public promotionalPrice?: Money,
    public minimumPromotionalPrice?: Money,
    public unitOriginalPrice?: Money,
    public logoUrl?: string,
    public itemMiscellaneous?: ItemMiscellaneous,
    public sellingOption?: SellingOption,
    public productInfo?: ProductInfo,
    public productTags?: ProductTag[],
    public tags?: string[],
  ) {}

  toJSON(): ItemJSON {
    return {
      order: this.order,
      id: this.id,
      instanceId: this.instanceId ?? "",
      code: this.code,
      category: this.category,
      categoryCode: this.categoryCode,
      details: this.details,
      description: this.description,
      unitPrice: this.unitPrice.toJSON(),
      unitMinPrice: this.unitMinPrice.toJSON(),
      promotionalPrice: this.promotionalPrice?.toJSON(),
      minimumPromotionalPrice: this.minimumPromotionalPrice?.toJSON(),
      unitOriginalPrice: this.unitOriginalPrice
        ? this.unitOriginalPrice.toJSON()
        : undefined,
      choices: this.choices
        ? this.choices.map((choice) => choice.toJSON())
        : [],
      logoUrl: this.logoUrl,
      itemMiscellaneous: this.itemMiscellaneous,
      sellingOption: this.sellingOption,
      productInfo: this.productInfo,
      productTags: this.productTags,
      tags: this.tags,
    };
  }

  getLogoUrl(size: Sizes = Sizes.HIGH) {
    return this.logoUrl
      ? getImageURL(Folders.DISH, this.logoUrl, size, Quality.HIGH)
      : "/images/no_image.png";
  }

  hasGarnishItems() {
    return this.choices.length > 0;
  }

  hasPizzaFlavors() {
    return this.choices.some((choice) => choice.isPizzaFlavor());
  }

  hasMandatoryGarnishItems() {
    return this.choices.some((choice) => choice.isMandatory());
  }

  getBasePrice() {
    if (!this.hasMandatoryGarnishItems() && this.unitPrice.getValue()) {
      return this.promotionalPrice ?? this.unitPrice;
    }

    if (!this.hasMandatoryGarnishItems() && !this.unitPrice.getValue()) {
      return this.minimumPromotionalPrice ?? this.unitMinPrice;
    }

    const minGarnishPrice = this.choices
      .filter((choice) => choice.isMandatory())
      .map(
        (choice) =>
          Math.min(...choice.items.map((item) => item.unitPrice.getValue())) *
          choice.min,
      )
      .reduce((acc, curr) => acc + curr, 0);

    return new Money(
      (this.promotionalPrice ?? this.unitPrice).getValue() + minGarnishPrice,
    );
  }

  isPromotionalItem() {
    return (
      this.promotionalPrice ||
      (!this.hasMandatoryGarnishItems() &&
        this.unitOriginalPrice &&
        (this.unitMinPrice.getValue() < this.unitOriginalPrice.getValue() ||
          this.unitPrice.getValue() < this.unitOriginalPrice.getValue()))
    );
  }

  isWeigthVariable() {
    return this.sellingOption?.availableUnits.includes("WEIGHT");
  }

  getDetails() {
    if (this.isWeigthVariable()) {
      return `A granel (${this.getProductWeight()} cada)`;
    } else {
      return this.details;
    }
  }

  portionSizesDictionary = {
    [PortionSizesTags.ServesOne]: "Serve 1 pessoa",
    [PortionSizesTags.ServesTwo]: "Serve 2 pessoas",
    [PortionSizesTags.ServesThree]: "Serve 3 pessoas",
    [PortionSizesTags.ServerFour]: "Serve 4 pessoas",
    [PortionSizesTags.NotApplicable]: "",
  };

  getPortionSize(size: PortionSizesTags) {
    return this.portionSizesDictionary[size] || "";
  }

  dietaryRestrictionsDictionary = {
    [DietaryRestrictionsTags.AlcoholicDrink]: {
      icon: DrinksFilled,
      label: "Bebida alcóolica",
    },
    [DietaryRestrictionsTags.GlutenFree]: {
      icon: GlutenFreeFilled,
      label: "Sem glúten",
    },
    [DietaryRestrictionsTags.LacFree]: {
      icon: LactoseFreeFilled,
      label: "Sem lactose",
    },
    [DietaryRestrictionsTags.Organic]: {
      icon: OrganicFilled,
      label: "Orgânico",
    },
    [DietaryRestrictionsTags.SugarFree]: {
      icon: ZeroSugarFilled,
      label: "Sem açucar",
    },
    [DietaryRestrictionsTags.Vegan]: { icon: VeganFilled, label: "Vegano" },
    [DietaryRestrictionsTags.Vegetarian]: {
      icon: VegetarianFilled,
      label: "Vegetariano",
    },
  };

  getDietaryRestrictions(restriction: DietaryRestrictionsTags) {
    return this.dietaryRestrictionsDictionary[restriction] || null;
  }

  isDietaryRestrictionTag(productTag: ProductTag) {
    return productTag.group === ProductTagGroup.DietaryRestriction;
  }

  isPortionSizeTag(productTag: ProductTag) {
    return productTag.group === ProductTagGroup.PortionSize;
  }

  getFormattedUnit() {
    if (!this.isWeigthVariable()) return "unidade";
    return this.getProductWeight();
  }

  getProductWeight() {
    if (!this.sellingOption?.incremental) return "";
    let quantity = this.sellingOption.incremental;
    let unit = "g";
    if (quantity >= 1000) {
      quantity = quantity / 1000;
      unit = "kg";
    }
    return `${quantity}${unit}`.replace(".", ",");
  }

  getWeightPrice() {
    if (!this.isWeigthVariable() || !this.sellingOption) {
      return new Money(0);
    }
    if (this.itemMiscellaneous?.rawPrice?.price) {
      const { price } = this.itemMiscellaneous.rawPrice;
      return new Money(price.replace(".", ","));
    }
    const weight = this.sellingOption.incremental / 1000;
    return new Money(this.getBasePrice().getValue() / weight);
  }

  getPortionSizeInformation() {
    if (this.productTags) {
      const portionSize = this.productTags.find(this.isPortionSizeTag);

      if (portionSize) {
        return this.getPortionSize(portionSize.tags[0] as PortionSizesTags);
      }
    }

    return "";
  }

  getItemSpecifications() {
    const price = this.getBasePrice().format();
    const portionSize = this.getPortionSizeInformation();
    const productWeight = this.getProductWeight();

    return (
      price +
      (portionSize.length ? ` - ${portionSize}` : "") +
      (productWeight.length ? ` (${productWeight})` : "")
    );
  }

  hasDietaryRestriction() {
    if (this.productTags) {
      return this.productTags.some(this.isDietaryRestrictionTag);
    }

    return false;
  }

  getDietaryRestrictionInformation() {
    if (this.productTags) {
      const dietaryRestrictions = this.productTags.find(
        this.isDietaryRestrictionTag,
      );

      if (dietaryRestrictions) {
        return (dietaryRestrictions as DietaryRestrictionTag).tags.map((tag) =>
          this.getDietaryRestrictions(tag),
        );
      }
    }

    return [];
  }

  calculateOriginalPrice(
    merchant: Merchant,
    quantity: number,
    selectGarnishItems?: Record<string, CheckoutGarnishItem[]>,
  ) {
    const price = this.unitOriginalPrice ?? this.unitPrice;
    if (!this.hasGarnishItems() || !selectGarnishItems) {
      return price.multiply(quantity);
    }
    const choicesPrice = this.calculateChoicesPrice(
      merchant,
      quantity,
      selectGarnishItems,
    );
    return choicesPrice.add(price).multiply(quantity);
  }

  calculatePrice(
    merchant: Merchant,
    quantity: number,
    selectGarnishItems?: Record<string, CheckoutGarnishItem[]>,
  ) {
    if (!this.hasGarnishItems() || !selectGarnishItems) {
      return (this.promotionalPrice ?? this.unitPrice).multiply(quantity);
    }
    const choicesPrice = this.calculateChoicesPrice(
      merchant,
      quantity,
      selectGarnishItems,
    );
    return choicesPrice
      .add(this.promotionalPrice ?? this.unitPrice)
      .multiply(quantity);
  }

  calculateChoicesPrice(
    merchant: Merchant,
    quantity: number,
    selectGarnishItems: Record<string, CheckoutGarnishItem[]>,
  ): Money {
    if (
      this.hasGarnishItems() &&
      this.hasPizzaFlavors() &&
      !merchant.hasIndividualCharge()
    ) {
      const itemWithPizzaPriceCalculator = new ItemWithPizzaPriceCalculator(
        this,
        selectGarnishItems,
        quantity,
      );
      return itemWithPizzaPriceCalculator.calculate();
    }

    const choicesPrice = this.choices
      .map((choice) => selectGarnishItems[choice.code] ?? [])
      .flat(1)
      .map((garnishItem) =>
        garnishItem.catalogGarnishItem.unitPrice.multiply(garnishItem.quantity),
      )
      .reduce((acc, value) => acc.add(value), new Money(0));

    return choicesPrice;
  }

  getDiscountPercentage() {
    const oldValue = (this.unitOriginalPrice ?? this.unitPrice)?.getValue();
    const newValue = this.getBasePrice()?.getValue();
    if (!oldValue) return 0;
    const discountPercentage = ((oldValue - newValue) / oldValue) * 100;
    const roundedDiscountPercentage = Math.round(discountPercentage);
    return roundedDiscountPercentage;
  }
}
