import React from "react";
import * as S from "./styles";
import { useCatalog } from "@whitelabel-webapp/catalog/shared/catalog-store";
import Link from "next/link";
import { Icon, Text, Flex } from "@ifood/pomodoro-components";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { ChevronLeft, SearchOutlined } from "@ifood/pomodoro-icons";
import { useRouter } from "next/router";
import { CatalogLink, Item } from "@whitelabel-webapp/catalog/shared/models";
import { SmallItem } from "@app/domains/item";
import { DeliveryInfo } from "@whitelabel-webapp/merchant/shared/components";

type ItemGridLayoutProps = {
  title: string;
  catalogItems: Item[];
};

export const ItemGridLayout: React.VFC<ItemGridLayoutProps> = ({
  title,
  catalogItems,
}) => {
  const router = useRouter();
  const { catalog } = useCatalog();
  const { merchant } = useMerchant();
  const catalogLinks = catalog.links;

  const buildLinkHref = (link: CatalogLink) => {
    return `/${merchant.query}/${link.type}/${link.uuid}`;
  };

  return (
    <S.Content>
      <S.FirstColumn>
        <DeliveryInfo />
        <S.Aside>
          <Text fontWeight="500" color="black" fontSize="m" mb="16px">
            Categorias
          </Text>
          <S.Links>
            {catalogLinks?.map((link) => {
              return (
                <Link key={link.name} href={buildLinkHref(link)}>
                  {link.name}
                </Link>
              );
            })}
          </S.Links>
        </S.Aside>
      </S.FirstColumn>

      <S.Column>
        <S.TopGrid>
          <S.Breadcrumb>
            <Link href={`/${merchant.query}`}>Home</Link>
            <Flex>{">"}</Flex>
            <Link href={router.asPath}>{title}</Link>
          </S.Breadcrumb>

          <S.BreadcrumbMobile>
            <Icon
              size="s"
              component={ChevronLeft}
              onClick={() => router.push(`/${merchant.query}`)}
            />
            <S.Title>{title}</S.Title>
            <Icon
              width="20px"
              height="20px"
              component={SearchOutlined}
              onClick={() => router.push(`/${merchant.query}/busca`)}
            />
          </S.BreadcrumbMobile>

          <S.TitleBar>
            <S.Title>{title}</S.Title>
          </S.TitleBar>
        </S.TopGrid>

        {catalogItems ? (
          <S.ItemGrid role="datalist" aria-label="lista de produtos">
            {catalogItems.map((item) => (
              <SmallItem key={item.id} item={item} />
            ))}
          </S.ItemGrid>
        ) : (
          <Text>Nenhum item encontrado.</Text>
        )}
      </S.Column>
    </S.Content>
  );
};
