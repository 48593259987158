import { Text, Heading, Icon, Flex } from "@whitelabel-webapp/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.smaller};
  margin: ${({ theme }) => theme.space.small} 0;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space.regular};

  &:last-child {
    border: 0;
  }
`;

export const Description = styled(Flex)`
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space.regular};
`;

export const GarnishItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.smaller};

  color: ${({ theme }) => theme.colors.grayDarkest};
  font-weight: 200;

  &:last-child {
    margin-bottom: ${({ theme }) => theme.space.none};
  }
`;

export const GarnishQuantity = styled.span`
  margin-right: ${({ theme }) => theme.space.smallest};
  padding: ${({ theme }) => theme.space.smallest};

  background-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.radii.s};
`;

export const GarnishName = styled(Text)`
  margin-bottom: ${({ theme }) => theme.space.none};
`;

export const ImageWrapper = styled.div<{ saturate: number }>`
  flex: none;
  height: 64px;
  border-radius: 12px;
  @media (${belowDesktop}) {
    border-radius: 8px;
    height: 42px;
  }

  aspect-ratio: 1;
  background-color: white;
  border: solid 4px white;
  position: relative;
  filter: brightness(0.93);
  cursor: pointer;

  img {
    filter: ${({ saturate }) => `saturate(${saturate})`};
  }
`;

export const Observation = styled(Text)`
  margin-bottom: ${({ theme }) => theme.space.small};

  color: ${({ theme }) => theme.colors.grayDarkest};
  font-weight: 200;
  line-break: anywhere;
`;

export const PriceContainer = styled(Flex)`
  text-wrap: nowrap;
  justify-content: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.smallest};
`;

export const StrikethroughedText = styled(Text)`
  text-decoration-line: line-through;
  font-weight: 400;
  font-size: 18px;
  @media (${belowDesktop}) {
    font-size: 14px;
  }
`;

export const PriceText = styled(Text)`
  font-weight: 400;
  font-size: 18px;
  @media (${belowDesktop}) {
    font-size: 14px;
  }
`;

export const EditQuantity = styled.div`
  width: 116px;

  & > div > div {
    padding: 0;
    border: none;
    height: inherit;
    div {
      padding: 0;
    }
  }
`;

export const RemoveItem = styled(Text)`
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }

  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.fontSizes.s};
`;

export const RemoveIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  right: -10px;
  top: -10px;

  &:hover {
    filter: brightness(0.8);
  }

  svg {
    width: 12px;
    height: 12px;
    padding: 4px;
    color: white;
    border-radius: 50%;
    background-color: #eb0033;
  }
`;

export const ItemName = styled(Heading).attrs({
  forwardedAs: "h5",
})`
  margin: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grayDarkest};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.lineHeights.m};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.tsm};
    line-height: ${({ theme }) => theme.lineHeights.s};
  }
`;

export const UnavailableTag = styled(Text)`
  display: flex;
  font-weight: 700;
  width: fit-content;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.space.small};
  line-height: ${({ theme }) => theme.space.small};
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  margin-bottom: ${({ theme }) => theme.space.smallest};

  padding: 4px 12px;
  @media (${belowDesktop}) {
    padding: 2px 8px;
  }
`;

export const FindSimilar = styled.a`
  margin: auto 0;
  text-wrap: nowrap;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.tsm};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;
