import { authenticationAboyeur } from "@whitelabel-webapp/authentication/shared/config";
import {
  ACCESS_TOKEN_KEY,
  APP_VERSION_KEY,
  CUSTOMER_KEY,
  DEVICE_ID_KEY,
  PAYMENT_SECRET_KEY,
  PLATFORM,
  REFRESH_TOKEN_KEY,
  SESSION_ID_KEY,
} from "@whitelabel-webapp/authentication/shared/constants";
import {
  Customer,
  CustomerJSON,
} from "@whitelabel-webapp/authentication/shared/models";
import { isMobileBrowser } from "@whitelabel-webapp/shared/browser-utils";
import {
  getDeviceId,
  getIsDevelopment,
  getSessionId,
} from "@whitelabel-webapp/shared/config";
import { CookiesStorage } from "@whitelabel-webapp/shared/models";
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { CookieSetOptions } from "universal-cookie";

import pkg from "../../../../../package.json";
import { getClientIp } from "@whitelabel-webapp/shared/ip-utils";

export const WHITELABEL_PREFIX = "wl";

export const buildAccessTokenKey = () =>
  `${WHITELABEL_PREFIX}.${ACCESS_TOKEN_KEY}`;
export const buildRefreshTokenKey = () =>
  `${WHITELABEL_PREFIX}.${REFRESH_TOKEN_KEY}`;
export const buildCustomerKey = () => `${WHITELABEL_PREFIX}.${CUSTOMER_KEY}`;

export const getCookieSetOptions = (): CookieSetOptions => {
  return {
    path: "/",
    httpOnly: false,
    secure: !getIsDevelopment(),
    sameSite: "lax",
    maxAge: 31536000,
  } as CookieSetOptions;
};

export const getAccessToken = (): string | undefined => {
  const key = buildAccessTokenKey();
  const token = CookiesStorage.get(key);
  return token ?? undefined;
};

export const setAccessToken = (token: string) => {
  const key = buildAccessTokenKey();
  const cookieOptions = getCookieSetOptions();
  CookiesStorage.set(key, token, cookieOptions);
};

export const removeAccessToken = () => {
  const key = buildAccessTokenKey();
  const cookieOptions = getCookieSetOptions();
  CookiesStorage.delete(key, cookieOptions);
};

export const getRefreshToken = (): string | undefined => {
  const key = buildRefreshTokenKey();
  const token = CookiesStorage.get(key);
  return token ?? undefined;
};

export const setRefreshToken = (token: string) => {
  const key = buildRefreshTokenKey();
  const cookieOptions = getCookieSetOptions();
  CookiesStorage.set(key, token, cookieOptions);
};

export const removeRefreshToken = () => {
  const key = buildRefreshTokenKey();
  const cookieOptions = getCookieSetOptions();
  CookiesStorage.delete(key, cookieOptions);
};

export const getCustomerLocalStorage = (): CustomerJSON | undefined => {
  if (typeof window === "undefined") return;

  const key = buildCustomerKey();
  const customer = localStorage.getItem(key);

  if (!customer) return;
  return JSON.parse(customer);
};

export const setCustomerLocalStorage = (value: CustomerJSON) => {
  const key = buildCustomerKey();
  const storageValue = JSON.stringify(value);
  localStorage.setItem(key, storageValue);
};

export const removeCustomerLocalStorage = () => {
  const key = buildCustomerKey();
  localStorage.removeItem(key);
};

const setAppVersion = () => {
  const key = `${WHITELABEL_PREFIX}.${APP_VERSION_KEY}`;
  const cookieOptions = getCookieSetOptions();
  CookiesStorage.set(key, pkg.version, cookieOptions);
};

const setSessionId = () => {
  const key = `${WHITELABEL_PREFIX}.${SESSION_ID_KEY}`;
  const cookieOptions = getCookieSetOptions();
  const sessionId = getSessionId();
  CookiesStorage.set(key, sessionId, cookieOptions);
};

const setDeviceId = () => {
  const key = `${WHITELABEL_PREFIX}.${DEVICE_ID_KEY}`;
  const cookieOptions = getCookieSetOptions();
  const deviceId = getDeviceId();
  CookiesStorage.set(key, deviceId, cookieOptions);
};

export const buildPaymentSecret = (email: string) =>
  btoa(email + getDeviceId()).replace(/[^A-z\s\d][\\^]?/g, "");

export const setPaymentSecret = (email: string) => {
  const key = `${WHITELABEL_PREFIX}.${PAYMENT_SECRET_KEY}`;
  const cookieOptions = getCookieSetOptions();
  const paymentSecret = buildPaymentSecret(email);
  CookiesStorage.set(key, paymentSecret, cookieOptions);
};

export const setAuthenticationCookies = (
  accessToken: string,
  refreshToken: string,
  email?: string,
) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  setAppVersion();
  setDeviceId();
  setSessionId();

  if (email) setPaymentSecret(email);
};

export const getPaymentSecretCookie = () =>
  CookiesStorage.get(`${WHITELABEL_PREFIX}.${PAYMENT_SECRET_KEY}`);

export const hasPaymentSecretCookie = () => Boolean(getPaymentSecretCookie());

const injectAuthorizationHeaderInterceptor =
  () => (config: AxiosRequestConfig) => {
    const token = getAccessToken();
    if (!token) return config;

    if (!config.headers) config.headers = {};
    const authorizationHeader = `Bearer ${token}`;
    config.headers["Authorization"] = authorizationHeader;
    config.headers["X-Ifood-Device-Id"] = getDeviceId();
    config.headers["X-Ifood-Session-Id"] = getSessionId();
    config.headers["X-Ifood-Client-Ip"] = getClientIp();
    config.headers["app_version"] = pkg.version;
    config.headers["app_name"] = pkg.name;
    config.headers["platform"] = isMobileBrowser.any()
      ? PLATFORM.MOBILE
      : PLATFORM.DESKTOP;

    return config;
  };

export const createAuthenticatedAxiosClient = (
  baseURL: string,
  headers?: AxiosRequestHeaders,
) => {
  const client = axios.create({ baseURL, headers });

  client.interceptors.request.use(injectAuthorizationHeaderInterceptor());

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const refreshToken = getRefreshToken();

      if (!refreshToken) {
        removeCustomerLocalStorage();
        removeAllAuthCookies();
        return Promise.reject(error as Error);
      }

      if (
        !error.response ||
        error.response.status !== 401 ||
        originalRequest._retry
      ) {
        return Promise.reject(error as Error);
      }

      originalRequest._retry = true;
      removeAccessToken();
      authenticationAboyeur.events.authentication.retry();

      try {
        const { access_token, refresh_token } =
          await Customer.refreshAccessToken(refreshToken);

        setAccessToken(access_token);
        setRefreshToken(refresh_token);
        authenticationAboyeur.events.authentication.refreshAccessToken();

        return client(originalRequest);
      } catch (refreshTokenError) {
        removeCustomerLocalStorage();
        removeAllAuthCookies();
        return Promise.reject(refreshTokenError as Error);
      }
    },
  );

  return client;
};

export const removeAllAuthCookies = () => {
  removeAccessToken();
  removeRefreshToken();
};

export const isRefreshTokenMissing = () => {
  return !getRefreshToken();
};
